// import { TableRow } from '@mui/material';
import { blue, blueGrey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { daDK } from './locales';
import '@fontsource/varela-round/400.css';

// A custom theme for this app
let theme = createTheme({
    spacing: 8,
    breakpoints: {
        values: {
            xs: 0,
            sm: 760,
            md: 900,
            lg: 1200,
            xl: 1536,
            // xs: 0,    /* default: v4 = 0, v5 = 0 */
            // sm: 600,  /* default: v4 = 600, v5 = 600 */
            // md: 960,  /* default: v4 = 960, v5 = 900 */
            // lg: 1280, /* default: v4 = 1280, v5 = 1200 */
            // xl: 1920, /* default: v4 = 19200, v5 = 1536 */
        },
    },
    palette: {
        primary: {
            main: blue[400],
        },
        secondary: {
            main: blueGrey[400],
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            '"Varela Round"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

theme = createTheme(theme, {
    typography: {
        title: {
            fontSize: '3rem',
            color: "#fff"
        },
        logo: {
            fontSize: 28,
            fontWeight: 700,
            color: 'white',
            paddingLeft: '20px',
            minWidth: 'fit-content',
            // textTransform: 'uppercase',
            textShadow: '1px 1px 2px black, 0 0 25px #cecece, 0 0 5px #cecece',
            userSelect: 'none', /* supported by Chrome and Opera */
            webkitUserSelect: 'none', /* Safari */
            khtmlUserSelect: 'none', /* Konqueror HTML */
            mozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* Internet Explorer/Edge */
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                padding: 0,
                margin: 0,
                textShadow: 'none',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 20,
            },
        },
        menu: {
            fontSize: 28,
            fontWeight: 400,
            color: '#cccccc',
            padding: theme.spacing(0),
            // filter: 'drop-Shadow(-2px 5px 4px rgba(255,255,255,0.4)',
            textShadow: '1px 1px 2px black, 0 0 25px #cecece, 0 0 5px #cecece',
            userSelect: 'none', /* supported by Chrome and Opera */
            webkitUserSelect: 'none', /* Safari */
            khtmlUserSelect: 'none', /* Konqueror HTML */
            mozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* Internet Explorer/Edge */
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                textShadow: 'none',
                // padding: theme.spacing(1),
                // padding: 0,
                // margin: 0,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
                // padding: theme.spacing(1),
                // padding: 0,
                // margin: 0,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 20,
                // padding: theme.spacing(1),
                // padding: 0,
                // margin: 0,
            },
        },
        teaser: {
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
                // backgroundColor: theme.palette.secondary.main,
                fontSize: '2rem',
                // color: 'red',
            },
            [theme.breakpoints.up('sm')]: {
                // backgroundColor: theme.palette.secondary.main,
                fontSize: '2rem',
                // color: 'purple',
            },
            [theme.breakpoints.up('md')]: {
                // backgroundColor: theme.palette.secondary.main,
                fontSize: '2.4rem',
                // color: 'green',
            },
            [theme.breakpoints.up('lg')]: {
                // backgroundColor: theme.palette.secondary.main,
                fontSize: '3rem',
                // color: 'blue',
            },
            fontWeight: 700,
            color: 'white',
            // paddingLeft: '20px',
            // textShadow: '-5px -5px #fff',
            // textShadow: '-6px 6px 8px rgba(46,91,173,0.6)',
            textShadow: '-2px 5px 4px rgba(255,255,255,0.4)',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                * {
                    box-sizing: border-box;
                }
                html {
                    font-size: 14px;
                }
                body {
                    background-color: #06050D;
                }
                a:visited {
                    color: inherit;
                    text-decoration: none;
                  }
                  a:link {
                    color: inherit;
                    text-decoration: none;
                  }
                  a:hover {
                    color: inherit;
                    text-decoration: none;
                  }
                  a {
                    color: inherit;
                    text-decoration: none;
                  }
                //   input::placeholder {
                //     color: #ce8f35;
                //   }
            `
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                }
            }
        }
    },
    daDK,
});

export default theme;