import * as React from "react";
import PropTypes from "prop-types";

import { StaticImage } from 'gatsby-plugin-image';

const Layout = ({children}) => {
    return (
        <main style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }}>
            <StaticImage 
                src="../../images/background.jpg"
                // layout="fixed"
                layout="fullWidth"
                // layout="fixed"
                formats={["auto", "webp", "avif"]}
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                    // maxHeight: "100vh",
                    // maxHeight: "100%",
                    top: "0",
                    left: "0",
                    zIndex: "-1",
                    // height: "100vh",
                    // margin: 0,
                    // padding: 0,
                    // backgroundColor: "transparent",
                }}
                alt=""
            />
            {children}
        </main>
    )
}
    
Layout.propTypes = {
    children: PropTypes.node,
};
        
export default Layout;
