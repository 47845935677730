import * as React from "react";

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

import LanguageSwitch from "./LanguageSwitch";
import NavBar from "./NavBar";

const Header = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (mobile) {
        return (
            <header>
                <NavBar />
            </header>
        )
    } else {
        return (
            <header>
                <div style={{
                    position: "absolute",
                    display: 'flex',
                    justifySelf: "flex-end",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    right: "0",
                    width: "200px",
                    height: "40px",
                    zIndex: 10,
                }}>
                    <LanguageSwitch />
                </div>
                <NavBar />
            </header>
        )
    }
  }

  export default Header;