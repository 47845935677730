import * as React from "react";

// import { StaticQuery, graphql } from 'gatsby';

// import { Link } from "gatsby";
// import { Link, useI18next } from '../../../plugins/gatsby-plugin-react-i18next-99';
import { Link as I18nLink, useI18next } from '../../../plugins/gatsby-plugin-react-i18next-99';

import Flag from "react-country-flag";

const LanguageSwitch = (props) => {
    const {languages, originalPath} = useI18next();
    return (
        <>
            {languages.map((lang, i) => (
                <I18nLink key={i} to={originalPath} language={lang}>
                    <Flag
                        countryCode={lang === "en" ? "GB" : "DK"}
                        title={lang === "en" ? "English" : "Danish"}
                        aria-label={lang === "en" ? "English" : "Danish"}
                        style={{fontSize: '2rem', lineHeight: '2rem'}}
                    />
                </I18nLink>
            ))}
        </>
    )
}

export default LanguageSwitch;

// const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: {language: {eq: $language}}) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;

/*
<Link to="/en/">
<Flag countryCode="gb" title="English" style={{fontSize: '2rem', lineHeight: '2rem'}} />
</Link>
<Link to="/da/">
<Flag countryCode="dk" title="Danish" style={{fontSize: '2rem', lineHeight: '2rem'}} />
</Link>
*/