import * as React from "react";
// import PropTypes from "prop-types";

import { Link, useI18next } from '../../../plugins/gatsby-plugin-react-i18next-99';
import { StaticImage } from 'gatsby-plugin-image';

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const LogoImage = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
    if (mobile || tablet) {
        return (
            <StaticImage
                layout="fixed"
                width={57}
                height={37}
                alt="logo"
                src="../../images/logo.png"
                formats={["auto", "webp", "avif"]}
            />
        )
    } else {
        return (
            <StaticImage
                layout="fixed"
                width={86}
                height={56}
                alt="logo"
                src="../../images/logo.png"
                formats={["auto", "webp", "avif"]}
            />
        )
    }
}
const LogoText = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
    if (mobile) {
        return (
            <Typography variant="logo">Teams of the Future</Typography>
        )
    } else if (tablet) {
        return (
            <Typography variant="logo">Teams of the Future</Typography>
        )
    } else {
        return (
            <Typography variant="logo">Teams of the Future</Typography>
        )
    }
}
const Logo = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (mobile) {
        return (
            <LogoImage />
        )
    } else {
        return (
            <Container style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: "flex-start",
                alignItems: "center",
                minWidth: 'fit-content',
                paddingLeft: 0,
                paddingRight: 0,
            }}>
                <LogoImage />
                <LogoText />
            </Container>
        )
    }
}

const MenuLinks = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
    if (mobile) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    minWidth: "100%",
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
            >
                <Typography variant="menu">Learn</Typography>
                <Typography variant="menu">Network</Typography>
                <Typography variant="menu">Innovate</Typography>
            </div>
        )
    } else if (tablet) {
        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    flexGrow: 1,
                }}
            >
                <Typography variant="menu">Learn</Typography>
                <Typography variant="menu">Network</Typography>
                <Typography variant="menu">Innovate</Typography>
            </Container>
        )
    } else {
        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    flexGrow: 1,
                }}
            >
                <Typography variant="menu">Learn</Typography>
                <Typography variant="menu">Network</Typography>
                <Typography variant="menu">Innovate</Typography>
            </Container>    
        )
    }
}

const UserMenu = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
    if (mobile) {
        return (
            <AccountCircleOutlinedIcon
                style={{
                    color: '#111',
                    fontSize: '36px',
                    filter: 'drop-Shadow(-2px 5px 4px rgba(255,255,255,0.4)',
                }}
            />
        )
    } else if (tablet) {
        return (
            <AccountCircleOutlinedIcon
                style={{
                    color: '#111',
                    fontSize: '48px',
                    filter: 'drop-Shadow(-2px 5px 4px rgba(255,255,255,0.4)',
                }}
            />
        )
    } else {
        return (
            <AccountCircleOutlinedIcon
                style={{
                    color: '#111',
                    fontSize: '70px',
                    filter: 'drop-Shadow(-2px 5px 4px rgba(255,255,255,0.4)',
                }}
            />
        )
    }
}

const NavBar = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
    const {language} = useI18next();
    if (mobile) {
        return (
            <nav style={{
                width: '100%',
                paddingTop: "10px",
                paddingBottom: "10px",
                // display: "flex",
                // position: "relative",
                // flexDirection: "row",
                // width: '100%',
                // paddingLeft: '50px',
                // paddingRight: '50px',
                // paddingTop: "50px",
                // paddingBottom: "20px",
                // alignItems: "center",
                // backgroundColor: "green",
            }}>
                <div style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "row", 
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "50px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}>
                    <Link to="/" title={`lang:home:${language}`}>
                        <LogoImage />
                    </Link>
                    <LogoText />
                    <UserMenu />
                </div>
                <div style={{
                    display: "flex", 
                    flexDirection: "row",
                    flexGrow: 1,
                    justifyContent: "space-around",
                    alignItems: "center",
                    maxWidth: "100%",
                }}>
                    <MenuLinks />
                </div>
            </nav>
        )
    } else if (tablet) {
        return (
            <nav style={{
                display: "flex",
                position: "relative",
                flexDirection: "row",
                width: '100%',
                paddingLeft: '50px',
                paddingRight: '50px',
                paddingTop: "50px",
                paddingBottom: "20px",
                alignItems: "center",
                // backgroundColor: "green",
            }}>
                <div style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "row", 
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Link to="/" title={`lang:home:${language}`}>
                        <Logo />
                    </Link>
                </div>
                <div style={{
                    display: "flex", 
                    flexDirection: "row", 
                    flexGrow: 1,
                    justifyContent: "space-around",
                    alignItems: "left"
                }}>
                    <MenuLinks />
                </div>
                <UserMenu />
            </nav>
        )
    } else {
        return (
            <nav style={{
                display: "flex",
                position: "relative",
                flexDirection: "row",
                width: '100%',
                paddingLeft: '50px',
                paddingRight: '50px',
                paddingTop: "50px",
                paddingBottom: "20px",
                alignItems: "center",
                // backgroundColor: "green",
            }}>
                <div style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "row", 
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Link to="/" title={`lang:home:${language}`}>
                        <Logo />
                    </Link>
                </div>
                <div style={{
                    display: "flex", 
                    flexDirection: "row", 
                    flexGrow: 1,
                    justifyContent: "space-around",
                    alignItems: "left"
                }}>
                    <MenuLinks />
                </div>
                <UserMenu />
            </nav>
        )
    }
}

export default NavBar;