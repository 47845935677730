import * as React from "react";
import PropTypes from "prop-types";

// </I18nextContext>
// import { Helmet, I18nextContext } from "../gatsby-plugin-react-i18next-99"

import { Helmet } from "../../../plugins/gatsby-plugin-react-i18next-99";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../themes/mui";

import { LocationProvider } from "@reach/router"
// import Seo from "../../src/components/Seo";

import CssBaseline from "@mui/material/CssBaseline";

import Header from "./Header";
import Layout from "./Layout";

const AppShell = ({ children }) => {
    return (
        <React.Fragment>
            <Helmet>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                    <LocationProvider>
                        <Header />
                        <Layout>
                            {children}
                        </Layout>
                    </LocationProvider>
            </ThemeProvider>
        </React.Fragment>
    );
};

AppShell.propTypes = {
    children: PropTypes.node,
};

export default AppShell;
